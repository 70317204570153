<template>
    <div>
        <a-button class="add-btn" type="primary" @click="handleAdd">添加管理节点</a-button>
        <basic-table ref="tableRef2" v-bind="tableConfig2" @page-change="pageChange">
            <template #slotType="{ row }">
                <span>{{ nodeTypes[row.type] }}</span>
            </template>
        </basic-table>

        <a-modal v-model:visible="visible" :title="`${!isEditing ? '添加' : '编辑'}管理节点`" @cancel="cancel" @ok="ok"
            :confirm-loading="loading">
            <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 5 }">
                <a-form-item name="type" label="节点层级：">
                    <a-select v-model:value="formState.type" @change="changeType" :disabled="isEditing"
                        placeholder="请选择节点层级">
                        <a-select-option v-for="item in typeOptions" :key="item.value" :value="item.value">{{ item.label
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item name="parentBizNodeId" label="上级节点：">
                    <a-select v-model:value="formState.parentBizNodeId" :disabled="isEditing" placeholder="请选择上级节点">
                        <a-select-option v-for="item in parentNodeOptions" :key="item.value" :value="item.value">{{
                            item.label
                        }}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item name="name" label="节点名称：">
                    <a-input v-model:value="formState.name" placeholder="请输入节点名称"></a-input>
                </a-form-item>
                <a-form-item name="code" label="节点CODE：">
                    <a-input v-model:value="formState.code" :disabled="isEditing" placeholder="请输入管理节点的英文字符简写"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script setup>
import { BasicTable } from '@/components/basic/table'
import { ref, reactive, onMounted, computed, nextTick, defineProps, watch } from 'vue';
import { apiEnterpriseProject } from "@/api/tenant.js";
import { message, Modal } from 'ant-design-vue';

const props = defineProps({
    id: Number
})
const nodeTypes = {
    '01': '企业',
    '02': '城市',
    '00': '项目',
}

const tableConfig2 = reactive({
    showIndex: false,
    showSelect: false,
    pagination: false,
    isPageBottom: true,
    defaultExpandAllRows: true,
    isFinish: true,
    remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
    columns: [
        {
            title: "名称",
            type: "text",
            key: "name",
            width: '25%'
        },
        {
            title: "节点类型",
            type: "customize",
            slotName: "slotType",
        },
        {
            title: "节点编码",
            type: "text",
            key: "code",
        }
    ],
    action: {
        title: "操作",
        fixed: "right",
        items: [
            {
                icon: "FormOutlined",
                iconTip:'编辑',
                type: "icon",
                onClick: (row) => {
                    handleEdit(row);
                },
                visibleFn: (row) => {
                    return row.type == '01' || row.type == '02'
                }
            },
            {
                icon: "DeleteOutlined",
                iconTip:'删除',
                type: "icon",
                onClick: (row) => {
                    Modal.confirm({
                        title: '提醒',
                        content: '请确认是否删除？',
                        onOk() {
                            return new Promise((resolve) => {
                                apiEnterpriseProject.delete(row.bizNodeId).then(() => {
                                    message.success('删除成功');
                                    initTableData()
                                }).finally(() => {
                                    resolve()
                                })
                            })
                        }
                    });
                },
                visibleFn: (row) => row.type == '02'
            },
        ],
    },
})
const tableRef2 = ref();

const visible = ref(false);
const formRef = ref()
const formState = reactive({
    type: undefined,
    parentBizNodeId: undefined,
    name: '',
    code: ''
})
const rulesRef = computed(() => {
    return {
        type: [{ required: true, message: '请输入节点层级' }],
        parentBizNodeId: [{ required: formState.type != '01', message: '请选择上级节点' }],
        name: [{ required: true, message: '请输入节点名称' }],
        code: [{ required: true, message: '请输入节点CODE' }],
    }
});


const loading = ref(false)
const isEditing = ref(false)

const typeOptions = computed(() => {
    let options = [];
    for (const key in nodeTypes) {
        // 编辑状态，存在企业类型
        if (isEditing.value || key !== '01') {
            options.push({
                label: nodeTypes[key], value: key
            })
        }
    }
    return options
})

const parentNodeOptions = computed(() => {
    if (formState.type == '02') {
        let { name, bizNodeId } = tableRef2.value.dataSourceRef[0]
        return [{
            label: name,
            value: bizNodeId
        }]
    } else {
        let items = tableRef2.value.dataSourceRef[0].children
        return items ? items.map(e => ({ label: e.name, value: e.bizNodeId, })) : []
    }
})

watch(() => props.id, () => {
    initTableData()
})

onMounted(() => {
    initTableData()
})

const initTableData = () => {
    tableConfig2.isFinish = false;
    apiEnterpriseProject.list(props.id).then(({ result }) => {
        tableRef2.value.dataSourceRef = result
        tableConfig2.isFinish = true;
    })
}

const changeType = () => {
    formState.parentBizNodeId = undefined
};

const handleAdd = () => {
    visible.value = true;
    isEditing.value = false
}


const handleEdit = (e) => {
    visible.value = true
    isEditing.value = true
    formState.bizNodeId = e.bizNodeId
    for (const key in formState) {
        formState[key] = e[key]
    }
}

const pageChange = (pageIndex) => {
    tableRef2.value.paginationProps.current = pageIndex
    initTableData()
}
const cancel = () => {
    visible.value = false;
    nextTick(() => {
        formRef.value.resetFields();
    })
}

const ok = () => {
    formRef.value.validateFields().then(() => {
        loading.value = true
        apiEnterpriseProject[isEditing.value ? 'edit' : 'add'](formState).then(() => {
            visible.value = false
            message.success(`${isEditing.value ? '修改' : '添加'}成功`)
            initTableData()
            formRef.value.resetFields();
        }).finally(() => {
            loading.value = false
        })
    })
}


</script>

<style lang="less" scoped>
.add-btn {
    position: absolute;
    right: 20px;
    top: 7px;
}
</style>