<template>
  <div class="common-wrapper">
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="基本信息">
        <a-row :gutter="24">
          <a-col :span="10">
            <a-form ref="formRef" :model="formState" :rules="rulesRef" :label-col="{ span: 10 }">
              <a-form-item name="name" label="租户名称：">
                <a-input v-if="isEditing" v-model:value="formState.name" placeholder="请输入" :maxlength="50"></a-input>
                <span v-else>{{ formState.name }}</span>
              </a-form-item>
              <a-form-item name="code" label="租户编码：">
                <a-input v-if="isEditing" v-model:value="formState.code" placeholder="请输入" disabled
                  :maxlength="10"></a-input>
                <span v-else>{{ formState.code }}</span>
              </a-form-item>
              <a-form-item name="socialCreditCode" label="统一社会信用代码">
                <a-input v-if="isEditing" v-model:value="formState.socialCreditCode" placeholder="请输入"></a-input>
                <span v-else>{{ formState.socialCreditCode }}</span>
              </a-form-item>
              <a-form-item name="address" label="企业地址">
                <a-input v-if="isEditing" v-model:value="formState.address" placeholder="请输入" :maxlength="500"></a-input>
                <span v-else>{{ formState.address }}</span>
              </a-form-item>
              <a-form-item name="status" label="状态">
                <a-input v-if="isEditing" :value="formState.status == 0 ? '正常' : '停用'" :disabled='true'></a-input>
                <span v-else>{{ formState.status == 0 ? '正常' : '停用' }}</span>
              </a-form-item>
              <a-form-item name="businessLicense" label="营业执照：">
                <upload-image v-model:value="formState.businessLicense" @change="toValidate(['businessLicense'])"
                  :disabled="!isEditing" />
              </a-form-item>
              <a-form-item name="logo" label="企业logo：">
                <upload-image v-model:value="formState.logo" :disabled="!isEditing" @change="toValidate(['logo'])" />
              </a-form-item>
              <a-form-item :wrapper-col="{ span: 14, offset: 10 }">
                <template v-if="isEditing">
                  <a-button type="primary" @click="handleEdit">保存</a-button>
                  <a-button style="margin-left: 10px" @click="handleCancel">取消</a-button>
                </template>
                <template v-else>
                  <a-button type="primary" @click="isEditing = true">修改</a-button>
                  <a-button style="margin-left: 10px" @click="transferAdmin">移交</a-button>
                </template>
              </a-form-item>
            </a-form>
          </a-col>
          <a-col :span="10">
            <a-form :model="formState" :label-col="{ span: 8 }">
              <a-form-item name="adminUserName" label="管理员账号：">
                <a-input v-model:value="formState.adminUserName" :maxlength="50" disabled></a-input>
              </a-form-item>
              <a-form-item name="adminNickName" label="管理员姓名：">
                <a-input v-model:value="formState.adminNickName" disabled :maxlength="50"></a-input>
              </a-form-item>
              <a-form-item name="adminMobile" label="管理员电话：">
                <a-input v-model:value="formState.adminMobile" disabled></a-input>
              </a-form-item>
              <a-form-item name="adminEmail" label="管理员邮箱：">
                <a-input v-model:value="formState.adminEmail" disabled></a-input>
              </a-form-item>
              <a-form-item name="remark" label="备注：">
                <a-textarea v-model:value="formState.remark" disabled></a-textarea>
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
        <a-modal v-model:visible="visible" title="移交管理员" @ok="handleTransfer" :confirm-loading="loading">
          <a-form :model="adminForm" :label-col="{ span: 5 }">
            <a-form-item name="name" label="姓名：">
              <a-select v-model:value="adminForm.adminNickName" @change="handleAdminChange">
                <a-select-option v-for="item in userList" :key="item.userId" :value="item.userId">{{ item.nickname
                }}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item name="name" label="登录名：">
              <a-input v-model:value="adminForm.adminUserName" :maxlength="50" disabled></a-input>
            </a-form-item>
            <a-form-item name="adminEmail" label="邮箱：">
              <a-input v-model:value="adminForm.adminEmail" disabled></a-input>
            </a-form-item>
            <a-form-item name="adminMobile" label="电话：">
              <a-input v-model:value="adminForm.adminMobile" disabled></a-input>
            </a-form-item>
            <a-form-item name="roleId" label="移交后角色：">
              <a-select v-model:value="adminForm.roleId" @change="handleAdminChange">
                <a-select-option v-for="item in roleList" :key="item.id" :value="item.id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </a-modal>
      </a-tab-pane>
      <a-tab-pane key="2" tab="企业项目">
        <BusinessProject :id="route.query.id" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script setup>
// defineOptions({
//   name: 'BusinessInfo'
// })

import { ref, reactive, onMounted, watch } from 'vue';
import { useRoute } from "vue-router";

import { apiEnterprise } from "@/api/tenant.js";
import { message } from 'ant-design-vue';
import BusinessProject from './components/businessProject.vue';
import UploadImage from '../../../../components/basic/upload/uploadImage.vue';
const route = useRoute();

const activeKey = ref('1')
const isEditing = ref(false)
const formState = reactive({
  name: '',
  code: '',
  socialCreditCode: '',
  businessLicense: '',
  logo: '',
  address: '',
  status: '',
  remark: '',
  adminUserName: '',
  adminNickName: '',
  adminEmail: '',
  adminMobile: '',
})
const originFormState = reactive({})
const formRef = ref()

const rulesRef = reactive({
  name: [{ required: true, message: '请输入租户名称' }],
  code: [{ required: true, message: '请输入租户编码' }],
  socialCreditCode: [{ required: true, message: '请输入统一社会信用代码' }],
  address: [{ required: true, message: '请输入企业地址' }],
  businessLicense: [{ required: true, message: '请上传营业执照' }],
  logo: [{ required: true, message: '请上传企业logo' }],
});


const visible = ref(false)
const loading = ref(false)
const adminForm = reactive({
  adminUserName: '',
  adminNickName: '',
  adminEmail: '',
  adminMobile: '',
  roleId: ''
})
const userList = ref([])
const roleList = ref([])


onMounted(() => {
  initData()
})

watch(() => route.fullPath, () => {
  initData()
})


const initData = () => {
  apiEnterprise.detail(route.query.id).then(res => {
    let result = res.result;
    for (const key in formState) {
      formState[key] = result[key]
    }
    for (const key in result) {
      originFormState[key] = result[key]
    }
  })
}

const handleEdit = () => {
  formRef.value.validateFields().then(() => {
    let m = {
      id: originFormState.id,
      name: formState.name,
      code: formState.code,
      socialCreditCode: formState.socialCreditCode,
      businessLicense: formState.businessLicense,
      logo: formState.logo,
      address: formState.address,
    }
    apiEnterprise.edit(m).then(() => {
      message.success('修改成功')
      isEditing.value = false
    })
  });

}
const handleCancel = () => {
  isEditing.value = false
  for (const key in formState) {
    formState[key] = originFormState[key]
  }
  toValidate(['businessLicense', 'logo'])
  formRef.value.clearValidate()
}

const transferAdmin = () => {
  visible.value = true
  apiEnterprise.userList(route.query.id).then(res => {
    userList.value = res.result
  })
  apiEnterprise.transferRoleList().then(res => {
    roleList.value = res.result
  })
}
const handleAdminChange = (e) => {
  let item = userList.value.find(user => user.userId === e)
  if (item) {
    adminForm.id = item.userId
    adminForm.adminNickName = item.nickname
    adminForm.adminUserName = item.username
    adminForm.adminEmail = item.email
    adminForm.adminMobile = item.mobile
  }
}
const handleTransfer = () => {
  let m = {
    userId: adminForm.id,
    roleId: adminForm.roleId,
    tenantId: originFormState.id
  }
  loading.value = true
  apiEnterprise.transferEnterpriseAdmin(m).then(() => {
    message.success('移交成功')
  }).finally(() => {
    loading.value = false
  })
}

const toValidate = (keys) => {
  formRef.value.validateFields(keys)
}


</script>
<style lang='less' scoped>
.common-wrapper {
  position: relative;
  padding-top: 0
}
</style>